import { Grid, Typography } from '@mui/material'
import React, { FC } from 'react'
import { useSelector } from 'react-redux'
import styled from 'styled-components'
import { StoreToolType } from '../../../../interfaces/store-tool.interface'
import { IReduxState } from '../../../../state/interfaces/state.interface'
import { formatPrice } from '../../../../utils/currency'

interface IProps {
    readonly storeTool: StoreToolType
}

const StyledContainer = styled.div`
    display: flex;
    flex-direction: column;
`

const StyledImg = styled.div<{ source: string }>`
    background-image: ${({ source }) => `url(${source})`};
    background-repeat: no-repeat;
    background-size: contain;
    padding-right: 20px;
    width: calc(100% - 20px);
    height: 100%;
`

const StyledToolInfo = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
`

const StyledRow = styled.div`
    display: flex;
    justify-content: space-between;

    &:not(:last-child) {
        margin-bottom: 16px;
    }
`

const StyledRowInfo = styled.div`
    max-width: 100%;
`

const StyledAvailabilityRow = styled(StyledRow)`
    align-items: flex-end;
`

const StyledCode = styled.div`
    display: flex;
    flex-grow: 1;
`

const StyledCodeLabelTypography = styled(Typography)`
    color: var(--text-disabled-color);
    font-size: 16px;

    ${({ theme }) => theme.breakpoints.down('sm')} {
        font-size: 14px;
    }
`

const StyledCodeTypography = styled(Typography)`
    margin-left: 4px;
    font-size: 16px;

    ${({ theme }) => theme.breakpoints.down('sm')} {
        font-size: 14px;
    }
`

const StyledDesktopSubtotal = styled.div`
    ${({ theme }) => theme.breakpoints.down('sm')} {
        display: none;
    }
`

const StyledSubtotalTypography = styled(Typography)`
    font-size: 24px;
    font-weight: 500;
`

const OrderItem: FC<IProps> = ({ storeTool }) => {
    const cartTools = useSelector((state: IReduxState) => state.tools.cartTools)
    const cartTool = cartTools.find(({ id }) => id === storeTool.id)

    if (!cartTool) {
        return null
    }

    const subtotal = formatPrice(storeTool.price * cartTool.quantity)

    return (
        <StyledContainer>
            <Grid container>
                <Grid item sm={3} xs={4}>
                    <StyledImg
                        source={`${process.env.GATSBY_CDN_HOST}/${storeTool.attributes.code}_md`}
                    />
                </Grid>
                <Grid item sm={9} xs={8}>
                    <StyledToolInfo>
                        <StyledRow>
                            <StyledRowInfo>
                                <StyledCode>
                                    <StyledCodeLabelTypography>id:</StyledCodeLabelTypography>
                                    <StyledCodeTypography>
                                        {storeTool.attributes.code}
                                    </StyledCodeTypography>
                                </StyledCode>
                            </StyledRowInfo>
                        </StyledRow>
                        <StyledAvailabilityRow>
                            <StyledDesktopSubtotal>
                                {cartTool.quantity} x{' '}
                                <StyledSubtotalTypography>{subtotal} </StyledSubtotalTypography>
                            </StyledDesktopSubtotal>
                        </StyledAvailabilityRow>
                    </StyledToolInfo>
                </Grid>
            </Grid>
        </StyledContainer>
    )
}

export default OrderItem

import { PageProps } from 'gatsby'
import React, { FC, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import Layout from '../components/common/Layout'
import SEO from '../components/common/SEO'
import { useFetchStoreTools } from '../hooks/use-fetch-store-tools'
import { setCartTools } from '../state/actions/tools/set-cart-tools'
import { AppDispatchType } from '../state/interfaces/app-dispatch.type'
import { IReduxState } from '../state/interfaces/state.interface'
import { fetchCartToolsFromLS } from '../utils/local-storage'
import ListOrderedTools from '../components/Cart/OrderPaypal'
import styled from 'styled-components'
import { Typography } from '@mui/material'

const StyledContainer = styled.div`
    padding-top: 32px;
    padding-left: 32px;
    padding-right: 32px;

    ${({ theme }) => theme.breakpoints.down('sm')} {
        padding-top: 20px;
    }
`
const StyledTitleTypography = styled(Typography)`
    font-size: 30px;
    line-height: 40px;
    font-weight: 300;

    ${({ theme }) => theme.breakpoints.down('sm')} {
        font-size: 20px;
    }
`

interface IPurchaseUnits {
    shipping: {
        address: {
            address_line_1: string
            admin_area_1: string
            admin_area_2: string
            country_code: string
            postal_code: string
        }
        name: {
            full_name: string
        }
    }
}
interface ILocationState {
    payer: {
        key: string
        create_time: string
        id: string
        intent: string
        payer: {
            address: {
                country_code: string
            }
            email_address: string
            name: {
                given_name: string
                surname: string
            }
            payer_id: string
        }
        purchase_units: IPurchaseUnits[]
    }
}

const OrderPage: FC<PageProps<Record<string, unknown>, Record<string, unknown>, ILocationState>> =
    ({ location }) => {
        const reduxDispatch: AppDispatchType = useDispatch()
        const cartTools = useSelector((state: IReduxState) => state.tools.cartTools)

        if (!location.state) {
            return <></>
        }
        const data = location.state?.payer
        const cartToolIds = cartTools.map(({ id }) => id)

        const storeTools = useFetchStoreTools(cartToolIds)

        if (!storeTools) {
            return <>Loading...</>
        }
        async function fetchUrl() {
            const response = await fetch('https://api.sauter-tools.com/orders', {
                method: 'POST',
                mode: 'cors',
                cache: 'no-cache',
                credentials: 'omit',
                headers: {
                    'Content-Type': 'application/json',
                },
                redirect: 'follow',
                referrer: 'client',
                body: JSON.stringify({ paypal: data, cartTools }),
            })
            const json = await response.json().then()
            console.log(data)
            console.log(json)
            console.log(storeTools)
        }

        useEffect(() => {
            const cartTools = fetchCartToolsFromLS()
            reduxDispatch(setCartTools(cartTools))
            fetchUrl()
        }, [])

        return (
            <Layout>
                <SEO title='Order complete' />
                {data ? (
                    <StyledContainer>
                        <StyledTitleTypography variant='h4'>
                            Thank you for your order!
                        </StyledTitleTypography>
                        <div>
                            <p>
                                Your order has been received and is now being processed. Here you
                                will find an overview of your data and the ordered items.
                            </p>
                            <div>
                                First name: {data.payer.name.given_name}
                                <br />
                                Last name: {data.payer.name.surname}
                                <br />
                                E-mail: {data.payer.email_address}
                                <br />
                                <br />
                                <b>Shipping address</b>
                                <br />
                                {data.purchase_units[0].shipping.name.full_name}
                                <br />
                                {data.purchase_units[0].shipping.address.address_line_1}
                                <br />
                                {data.purchase_units[0].shipping.address.postal_code}
                                {data.purchase_units[0].shipping.address.admin_area_1}
                                <br />
                                {data.purchase_units[0].shipping.address.country_code}
                            </div>
                        </div>
                        <ListOrderedTools storeTools={storeTools} />
                    </StyledContainer>
                ) : (
                    <StyledContainer>
                        <StyledTitleTypography variant='h4'>
                            The process was not completed. Try again. If the error persists, contact
                            the admin.
                        </StyledTitleTypography>
                    </StyledContainer>
                )}
            </Layout>
        )
    }

export default OrderPage

import { Grid, Typography, useMediaQuery, useTheme } from '@mui/material'
import React, { FC } from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import { StoreToolType } from '../../../interfaces/store-tool.interface'
import OrderItem from './OrderItem'

interface IProps {
    readonly storeTools: StoreToolType[]
}

const StyledContainer = styled.div`
    padding-top: 32px;

    ${({ theme }) => theme.breakpoints.down('sm')} {
        padding-top: 20px;
    }
`

const StyledDivider = styled.div`
    width: 100vw;
    height: 1px;
    background-color: rgba(0, 0, 0, 0.24);
    margin-top: 20px;
    margin-left: calc(-1 * var(--center-content-padding));
`

const StyledTitleTypography = styled(Typography)`
    font-size: 30px;
    line-height: 40px;
    font-weight: 300;

    ${({ theme }) => theme.breakpoints.down('sm')} {
        font-size: 20px;
    }
`

const StyledContentGrid = styled(Grid)`
    margin-top: 40px;

    ${({ theme }) => theme.breakpoints.down('sm')} {
        margin-top: 20px;
    }
`

const StyledCartList = styled.ul`
    list-style: none;
    padding: 0;
    margin: 0 0 40px 0;
`

const StyledCartItemContainer = styled.li`
    &:not(:last-child) {
        padding-bottom: 52px;
        margin-bottom: 52px;
        border-bottom: 1px solid rgba(0, 0, 0, 0.24);
    }

    ${({ theme }) => theme.breakpoints.down('sm')} {
        &:not(:last-child) {
            padding-bottom: 20px;
            margin-bottom: 20px;
        }
    }
`

const ListOrderedTools: FC<IProps> = ({ storeTools }) => {
    const { t } = useTranslation()

    const theme = useTheme()
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'))

    return (
        <StyledContainer>
            <Grid container direction='column'>
                <Grid container item justifyContent='space-between' sm={8} xs={12}>
                    <StyledTitleTypography variant='h4'>
                        {t('paypal:order-overview-title')}
                    </StyledTitleTypography>
                </Grid>
            </Grid>
            {isMobile && <StyledDivider />}
            <StyledContentGrid container justifyContent='space-between'>
                <Grid item md={8} xs={12}>
                    <StyledCartList>
                        {storeTools.map((storeTool) => {
                            return (
                                <StyledCartItemContainer key={storeTool.id}>
                                    <OrderItem storeTool={storeTool} />
                                </StyledCartItemContainer>
                            )
                        })}
                    </StyledCartList>
                </Grid>
            </StyledContentGrid>
        </StyledContainer>
    )
}

export default ListOrderedTools
